import UserPlanEnum from '@/constants/UserPlanEnum'
import UserRoleEnum from '@/constants/UserRoleEnum'

const ignoredSteps = ['checklistCompleted', 'industryStandardsCompleted', 'ideaScoreCompleted']

export const getters = {
  getRole: (state) => {
    return state.role
  },

  getOwner: (state) => {
    return state.owner
  },

  isRoleGuest: (state) => {
    return state.role === UserRoleEnum.GUEST
  },

  isRoleBuddy: (state) => {
    return state.role === UserRoleEnum.BUDDY
  },

  isRoleOwner: (state) => {
    return state.role === UserRoleEnum.OWNER
  },

  isOwnerSubscribed: (state) => {
    return state.ownerSubscription.subscribed
  },

  isOwnerDreamer: function (state) {
    if (state.ownerSubscription.subscription) {
      return state.ownerSubscription.subscription.plan === UserPlanEnum.DREAMER
    }

    return null
  },

  isOwnerFounder: function (state) {
    if (state.ownerSubscription.subscription) {
      return state.ownerSubscription.subscription.plan === UserPlanEnum.FOUNDER
    }

    return null
  },

  isOwnerTeamPro: function (state) {
    if (state.ownerSubscription.subscription) {
      return state.ownerSubscription.subscription.plan === UserPlanEnum.TEAM_PRO
    }

    return null
  },

  getOwnerAiRequests: (state) => {
    return state.ownerAiRequests
  },

  isIdeaSelected: (state) => {
    return state.id !== 0
  },

  isIdeaFromTemplate: (state) => {
    return state.fromTemplate
  },

  completedStepsCount: (state) => {
    return state.completedStepsCount
  },

  getIdeaId: (state) => {
    return state.id
  },

  getOwnerSubscription: (state) => {
    return state.ownerSubscription.subscription
  },

  getGuideExample: (state) => (type) => {
    return state.ideaGuideExamples[type]
  },

  getTip: (state) => (type) => {
    return state.tips[type]
  },

  getBusinessPlanSections: (state) => {
    return state.journal.journalSwitchBars
  },

  getBusinessPlanSectionByName: (state) => (sectionName) => {
    return state.journal.journalSwitchBars.find(section => section.sectionName === sectionName)
  },

  getProducts: (state) => {
    return state.storyMode.concept.product.products
  },

  getPromotions: (state) => {
    return state.storyMode.concept.promotion.promotionalActivities
  },

  getUniqueSellingPropositions: (state) => {
    return state.storyMode.concept.product.uniqueSellingPropositions
  },

  getStepIsCompleted: (state) => (step) => {
    return state.storyMode.common.completedSteps[step]
  },

  getCompletedSteps: (state) => {
    return state.storyMode.common.completedSteps
  },

  isAllStepsCompleted: (state) => {
    return Object.keys(state.storyMode.common.completedSteps)
      .filter((step) => {
        return !ignoredSteps.includes(step)
      })
      .map(step => {
        return state.storyMode.common.completedSteps[step]
      })
      .every(v => v === true)
  },

  countStoryModeTotalSteps: (state) => {
    return Object.keys(state.storyMode.common.completedSteps).filter((step) => {
      return !ignoredSteps.includes(step)
    }).length
  },

  countStoryModeCompletedSteps: (state) => {
    return (Object.values(state.storyMode.common.completedSteps)).filter(Boolean).length
  },

  getIdeaWhy: (state) => {
    return state.storyMode.concept.idea.why
  },

  getIdeaWhat: (state) => {
    return state.storyMode.concept.idea.what
  },

  isIdeaWhyFromTemplate: (state) => {
    return state.storyMode.concept.idea.whyFromTemplate
  },

  isIdeaWhatFromTemplate: (state) => {
    return state.storyMode.concept.idea.whatFromTemplate
  },

  getDistributions: (state) => {
    return state.storyMode.concept.distribution.distributions
  },

  getPartners: (state) => {
    return state.storyMode.concept.partner.partners
  },

  getCustomers: (state) => {
    return state.storyMode.research.customers.customers
  },

  getCompetitors: (state) => {
    return state.storyMode.research.competitors.competitors
  },

  getStartupCosts: (state) => {
    return state.storyMode.setUp.startupCosts.startupCosts
  },

  getFinances: (state) => {
    return state.storyMode.setUp.financing.finances
  },

  getManagementTeam: (state) => {
    return state.storyMode.setUp.managementTeam.members
  },

  getStaffAndPayrolls: (state) => {
    return state.storyMode.setUp.staffAndPayrolls.staffAndPayrolls
  },

  getLaborBurden: (state) => {
    return state.storyMode.setUp.staffAndPayrolls.laborBurden
  },

  getDirectCosts: (state) => {
    return state.storyMode.forecast.directCosts.directCosts
  },

  getOtherOverheads: (state) => {
    return state.storyMode.forecast.otherOverheads.otherOverheads
  },

  getProfitAndCashFlowDividends: (state) => {
    return state.storyMode.forecast.profitAndCashFlow.dividends
  },

  getMarketingElements: (state) => {
    return state.storyMode.forecast.marketingBudget.marketingElements
  },

  getSalesTaxRate: (state) => {
    return state.storyMode.forecast.revenueStreams.salesTaxRate
  },

  getRevenueStreams: (state) => {
    return state.storyMode.forecast.revenueStreams.revenueStreams
  },

  getMarketPotential: (state) => {
    return state.storyMode.research.marketPotential
  },

  getSwotAnalysisStrengths: (state) => {
    return state.storyMode.research.swotAnalysis.strengths
  },

  getSwotAnalysisWeaknesses: (state) => {
    return state.storyMode.research.swotAnalysis.weaknesses
  },

  getSwotAnalysisOpportunities: (state) => {
    return state.storyMode.research.swotAnalysis.opportunities
  },

  getSwotAnalysisThreats: (state) => {
    return state.storyMode.research.swotAnalysis.threats
  },

  getIdeaScore: (state) => {
    return state.storyMode.validation.ideaScore
  },

  getSwotAnalysisHasItemsFromTemplate: (state) => {
    return state.storyMode.research.swotAnalysis.strengthsFromTemplate ||
      state.storyMode.research.swotAnalysis.opportunitiesFromTemplate ||
      state.storyMode.research.swotAnalysis.threatsFromTemplate ||
      state.storyMode.research.swotAnalysis.weaknessesFromTemplate
  },

  isSectionEverComplete: (state) => (section) => {
    return state.storyMode.common.completedSections[section]
  },

  isConceptCompleted: (state) => {
    return [
      state.storyMode.common.completedSteps.ideaCompleted,
      state.storyMode.common.completedSteps.productCompleted,
      state.storyMode.common.completedSteps.promotionCompleted,
      state.storyMode.common.completedSteps.distributionCompleted,
      state.storyMode.common.completedSteps.partnerCompleted
    ].every(v => v === true)
  },

  isResearchCompleted: (state) => {
    return [
      state.storyMode.common.completedSteps.marketCompleted,
      state.storyMode.common.completedSteps.customersCompleted,
      state.storyMode.common.completedSteps.competitorsCompleted,
      state.storyMode.common.completedSteps.swotAnalysisCompleted,
      state.storyMode.common.completedSteps.marketPotentialCompleted
    ].every(v => v === true)
  },

  isSetUpCompleted: (state) => {
    return [
      state.storyMode.common.completedSteps.employeesAndPayrollCompleted,
      state.storyMode.common.completedSteps.startupCostsCompleted,
      state.storyMode.common.completedSteps.financingCompleted,
      state.storyMode.common.completedSteps.managementTeamCompleted,
      state.storyMode.common.completedSteps.ownershipCompleted
    ].every(v => v === true)
  },

  isProjectionsCompleted: (state) => {
    return [
      state.storyMode.common.completedSteps.revenueStreamsCompleted,
      state.storyMode.common.completedSteps.directCostsCompleted,
      state.storyMode.common.completedSteps.marketingBudgetCompleted,
      state.storyMode.common.completedSteps.otherOverheadsCompleted,
      state.storyMode.common.completedSteps.profitAndCashFlowCompleted
    ].every(v => v === true)
  },

  getHasItemsFromTemplate: (state, getters) => (getter) => {
    return getters[getter].some(item => !item.isTouched && item.isFromTemplate)
  },

  getIdeaCreatedAt: (state) => {
    return state.createdAt
  },

  getAllOverviewUpdatedAt: (state, getters) => {
    return [
      getters.getStartupCosts,
      getters.getFinances,
      getters.getStaffAndPayrolls,
      getters.getRevenueStreams,
      getters.getDirectCosts,
      getters.getMarketingElements,
      getters.getOtherOverheads
    ]
      .reduce((a, item) => {
        return a.concat(item)
      }, [])
      .map(item => {
        return item.updatedAt
      })
  },

  isOverviewEverUpdated: (state, getters) => {
    return !getters.getAllOverviewUpdatedAt.every(element => element === getters.getIdeaCreatedAt)
  },

  getCatalogueIdeaTemplate: (state) => {
    return state.catalogueIdeaTemplate ? state.catalogueIdeaTemplate : null
  },

  isTipOpened: (state) => (tipName) => {
    return state.tipOpen[tipName]
  },

  getIdeaCurrency: (state) => {
    return state.ideaSettings.currency
  }
}
